<template>
  <div>
    This is an admin page, available only if the user has the "admin" role.
  </div>
</template>

<script>

export default {
  name: 'Admin',
  components: {
  },
};
</script>
